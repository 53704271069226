<template>
    <CRow class="justify-content-center">
        <CModal title="Aviso!" color="danger" :show.sync="showAlert">
            {{ msg }}
            <template #footer>
                <CButton @click="showAlert = false" color="danger">OK</CButton>
            </template>
        </CModal>
        <CCol sm="12" md="9" lg="6" xl="3">
            <CCard>
                <CCardHeader>
                    <CRow>
                        <CCol sm="12">
                            <h4 id="traffic" class="card-title mb-0">
                                Altere sua senha
                                <div class="card-header-actions">
                                    <CLink href="#/dashboard" class="card-header-action btn-close">
                                        <CIcon name="cil-x-circle"/>
                                    </CLink>
                                </div>
                            </h4>
                        </CCol>
                    </CRow>
                </CCardHeader>
                <CCardBody>
                    <CRow>
                        <CCol sm="12">
                            <CInput
                                placeholder="Senha atual"
                                type="password"
                                autocomplete="curent-password"
                                v-model="password"
                            />
                        </CCol>
                        <CCol sm="12">
                            <CInput
                                placeholder="Nova senha"
                                type="password"
                                autocomplete="curent-password"
                                v-model="newPass"
                            />
                        </CCol>
                        <CCol sm="12">
                            <CInput
                                placeholder="Repita a nova senha"
                                type="password"
                                autocomplete="curent-password"
                                v-model="repNewPass"
                            />
                        </CCol>
                        <CCol sm="12">
                            <CButton type="reset" color="primary" style="margin-top:28px" @click="updatePassword">
                                Salvar e fazer Logout
                            </CButton>
                        </CCol>
                    </CRow>
                </CCardBody>
            </CCard>
        </CCol>
    </CRow>
</template>

<script>
const axios = require('axios').default;
import functions from '@/functions';

export default {
    name:"FormularioPadrao",
    data(){
        return {
            showAlert: false,
            msg: '',
            repNewPass:'',
            newPass:'',
            password:'',
        }
    },
    methods:{
        ...functions,
        updatePassword(){
            if (this.repNewPass !== this.newPass){
                return this.onError(new Error('As senhas digitadas não conferem!'));
            }
            const token = localStorage.getItem('user-token');
            const config = {
                method: 'post',
                url: this.vhost('client/updatepassword'),
                headers: { "Content-Type": "application/json","x-access-token": token},
                data:{ newpassword: this.newPass, oldpassword: this.password }
            };
            axios(config)
                .then(()=> {
                    localStorage.setItem('user-token', '');
                    this.$router.push('/login');
                })
                .catch(this.onError)
        },
        onError(err){
            if (err.response && err.response.data && err.response.data.auth===false) {
                localStorage.setItem('user-token', '');
                this.$router.push('/login');
            } else if (err.response && err.response.data && err.response.data.erro){
                this.setAlert(err.response.data.erro);        
            } else {
                this.setAlert(err.message);
            }  
        },
        setAlert(message){
            this.msg=message;
            this.showAlert=(message !== undefined);
        },
    },
}
</script>

<style>

</style>